import React, { FC, useState } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";


export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  const [witchimage,setwitchimage]=useState(true);

function switchImage() {
  setTimeout(() => { setwitchimage(!witchimage);  }, 2500); 
}

switchImage();

  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
        DU 05 JUILLET AU 7 SEPTEMBRE 2024
        </span>
        <h2 className="font-semibold text-4xl mt-5">
          
        
Jusqu’à <strong className="text-green-500">62 % de remise</strong></h2> <h2 className="font-semibold text-3xl mt-5">sur les vols internationaux<br/><br/>
62 jours de validité </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
          <span className="block text-xl font-semibold">
            <b className="text-4xl font-semibold">62</b>ème anniversaire de l’indépendance
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              
            </span>
          </li>
        </ul>
      </div>


      <div className="flex-grow">


{ witchimage?
(<NcImage src="https://api.tassiliairlines.dz.tl/images/cinqjuillet.jpg" />)
:(<NcImage src="https://api.tassiliairlines.dz.tl/images/cinqjuillet2.jpg" />)
}
      </div>



    </div>
  );
};

export default SectionOurFeatures;
