import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import  React, { useState, useRef, useEffect } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionOurFeatures1 from "components/SectionOurFeatures/SectionOurFeatures1";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";


const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Illizi / إليزي",
    taxonomy: "category",
    count: 188000,
    thumbnail:
      "https://api.tassiliairlines.dz.tl/images/hublo1.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "El oued / الواد",
    taxonomy: "category",
    count: 120000,
    thumbnail:
      "https://api.tassiliairlines.dz.tl/images/hublo2.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Alger / الجزائر",
    taxonomy: "category",
    count: 48000,
    thumbnail:
      "https://api.tassiliairlines.dz.tl/images/hublo3.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Oran / وَهران",
    taxonomy: "category",
    count: 88000,
    thumbnail:
      "https://api.tassiliairlines.dz.tl/images/hublo4.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Béchar / بشار",
    taxonomy: "category",
    count: 28000,
    thumbnail:
      "https://api.tassiliairlines.dz.tl/images/hublo5.jpg",
  }
];

/*
const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Omra / Mecca",
    taxonomy: "category",
    count: 188000,
    thumbnail:
      "http://localhost/images/Meccah.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Sharem Echeikh",
    taxonomy: "category",
    count: 120000,
    thumbnail:
      "http://localhost/images/Sharm-El-Sheikh.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Paris",
    taxonomy: "category",
    count: 48000,
    thumbnail:
      "http://localhost/images/Paris.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Istambul",
    taxonomy: "category",
    count: 88000,
    thumbnail:
      "http://localhost/images/Istanbule.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Tunis",
    taxonomy: "category",
    count: 28000,
    thumbnail:
      "http://localhost/images/Tunis.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Alicante",
    taxonomy: "category",
    count: 38000,
    thumbnail:
      "https://pixels.tassiliairlines.dz.tl/pixels/free-photo-of-photo-of-the-crowd-on-the-beach-in-alicante-spain.jpeg",
  },
];
*/

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "#",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "#",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "#",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "#",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);


    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [callback]);

  return ref;
};


class MutedVideo extends HTMLVideoElement {
  constructor() {
    super();
    this.muted = true;
    // I also noticed that you used autoplay, so I added it too.
    this.autoplay = true;
  }

  setMuted() { this.muted=false; }
}

customElements.define("x-muted", MutedVideo, { extends: "video" });


const FloatingWindow = () => {

  const videoDiv = useOutsideClick(() => {
    setOpen(false);
    console.log('Clicked outside of MyComponent');
  });

  const [isOpen, setOpen] = useState(true);

  let [ismuted, setMuted] = React.useState(true);

function SetMuted()
{
  if (ismuted===false) setOpen(false);
  setMuted(false);
  ismuted=false;
  console.log("is muted:"+ismuted);

const htmlVideo=document.getElementById("myVideo")  as HTMLVideoElement;

let theVideo: HTMLVideoElement = htmlVideo;  

theVideo.muted = false;
  
}

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
if (isOpen)
return (
  
  (<div ref={videoDiv} className="fixed bottom-48 z-40 right-1/4 flex items-center" onClick={() => SetMuted()}>
      <video id="myVideo" className="rounded-3xl"  muted autoPlay width="750" height="500" preload="auto" src="https://api.tassiliairlines.dz.tl/images/video-promo-sitev-1.mp4" loop >
     </video>
    </div>)
  ); else return;
};

function PageHome() {


  const [open, SetOpen] = React.useState(false);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-14 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-0" />

        {/* SECTION2  */}
        <SectionOurFeatures1 />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>


        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          className="pt-24 sm:pt-24 md:pt-40 lg:pt-14 xl:pt-14"
          uniqueClassName="PageHome_s1"
        />
</div>


        {/* SECTION 
        <SectionGridCategoryBox />  */}
        
        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        <SectionVideos />

        {/* SECTION 
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />   
      </div>   */}


        {/* SECTION 
        <SectionHowItWork />*/}

        {/* SECTION 1 
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>*/}


        {/* SECTION
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}


        {/* SECTION 1 
        <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />*/}


        {/* SECTION 
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>*/}
      </div>

      {/*FloatingWindow()*/}



    </div>
  );
}

export default PageHome;
